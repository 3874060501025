var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ck-text-input", {
        attrs: {
          value: _vm.address_line_1,
          id: "address_line_1",
          label: "Address Line 1",
          type: "text",
          error: _vm.errors.get("address_line_1")
        },
        on: {
          input: function($event) {
            return _vm.onInput("address_line_1", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.address_line_2,
          id: "address_line_2",
          label: "Address Line 2",
          type: "text",
          error: _vm.errors.get("address_line_2")
        },
        on: {
          input: function($event) {
            return _vm.onInput("address_line_2", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.address_line_3,
          id: "address_line_3",
          label: "Address Line 3",
          type: "text",
          error: _vm.errors.get("address_line_3")
        },
        on: {
          input: function($event) {
            return _vm.onInput("address_line_3", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.city,
          id: "city",
          label: "City",
          type: "text",
          error: _vm.errors.get("city")
        },
        on: {
          input: function($event) {
            return _vm.onInput("city", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.county,
          id: "county",
          label: "County",
          type: "text",
          error: _vm.errors.get("county")
        },
        on: {
          input: function($event) {
            return _vm.onInput("county", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.postcode,
          id: "postcode",
          label: "Postcode",
          type: "text",
          error: _vm.errors.get("postcode")
        },
        on: {
          input: function($event) {
            return _vm.onInput("postcode", $event)
          }
        }
      }),
      _c("ck-select-input", {
        attrs: {
          value: _vm.country,
          id: "country",
          label: "Country",
          options: _vm.countries,
          error: _vm.errors.get("country")
        },
        on: {
          input: function($event) {
            return _vm.onInput("country", $event)
          }
        }
      }),
      _vm.errors.has("address_not_found")
        ? _c("gov-error-message", {
            attrs: { for: "postcode" },
            domProps: {
              textContent: _vm._s(_vm.errors.get("address_not_found"))
            }
          })
        : _vm._e(),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _c(
        "gov-form-group",
        {
          attrs: {
            invalid:
              _vm.errors.has("has_induction_loop") ||
              _vm.errors.has("has_wheelchair_access")
          }
        },
        [
          _c(
            "gov-fieldset-legend",
            { attrs: { size: "xl" } },
            [
              _c("gov-heading", { attrs: { size: "m" } }, [
                _vm._v("Accessibility information")
              ])
            ],
            1
          ),
          _c("gov-body", [
            _vm._v("Select which accessibility requirements the building meets")
          ]),
          _c(
            "gov-checkboxes",
            [
              _c("gov-checkbox", {
                attrs: {
                  value: _vm.has_induction_loop,
                  id: "has_induction_loop",
                  name: "has_induction_loop",
                  label: "Induction loop"
                },
                on: {
                  input: function($event) {
                    return _vm.onInput("has_induction_loop", $event)
                  }
                }
              }),
              _vm.errors.has("has_induction_loop")
                ? _c("gov-error-message", {
                    attrs: { for: "has_induction_loop" },
                    domProps: {
                      textContent: _vm._s(_vm.errors.get("has_induction_loop"))
                    }
                  })
                : _vm._e(),
              _c("gov-checkbox", {
                attrs: {
                  value: _vm.has_wheelchair_access,
                  id: "has_wheelchair_access",
                  name: "has_wheelchair_access",
                  label: "Wheelchair accessible"
                },
                on: {
                  input: function($event) {
                    return _vm.onInput("has_wheelchair_access", $event)
                  }
                }
              }),
              _vm.errors.has("has_wheelchair_access")
                ? _c("gov-error-message", {
                    attrs: { for: "has_wheelchair_access" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.errors.get("has_wheelchair_access")
                      )
                    }
                  })
                : _vm._e(),
              _c("gov-checkbox", {
                attrs: {
                  value: _vm.has_accessible_toilet,
                  id: "has_accessible_toilet",
                  name: "has_accessible_toilet",
                  label: "Accessible toilet"
                },
                on: {
                  input: function($event) {
                    return _vm.onInput("has_accessible_toilet", $event)
                  }
                }
              }),
              _vm.errors.has("has_accessible_toilet")
                ? _c("gov-error-message", {
                    attrs: { for: "has_accessible_toilet" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.errors.get("has_accessible_toilet")
                      )
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("ck-image-input", {
        attrs: {
          id: "image",
          label: "Location image",
          "existing-url": _vm.id
            ? _vm.apiUrl("/locations/" + _vm.id + "/image.png?v=" + _vm.now)
            : undefined
        },
        on: {
          input: function($event) {
            return _vm.onInput("image_file_id", $event.file_id)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }